const pageIdMap: {
  [key: string]: string
} = {
  // 主页
  '/': 'page_home',
  // 进入车型库页
  '/auto': 'page_garage',
  '/picture': 'page_car_picture',
  // 进入视频页
  '/video': 'page_video',
  // 热门车系排行榜
  '/popular': 'page_hot_series_rank_list',
  // 进入资讯主页
  '/feed': 'page_feed',
  // 进入车友圈主页
  '/car_fans_community': 'page_motor_forum',
  // 进入直播主页
  '/live': 'page_dcar_live_main',
  // 进入文章详情页
  article: 'page_article_detail',
  // 进入车系主页
  series: 'page_series_home',
  // 个人详情页
  profile: 'page_user_profile',
  // 进入车系实拍图片页
  seriesImages: 'page_series_real_picture',
  // 进入车系精选视频页
  seriesVideo: 'page_series_video_list',
  // 进入车系视频说明书页
  seriesVideoManual: 'page_series_instruction_video_detail',
  // 进入车系图片详情页
  seriesImageskj: 'page_series_picture_detail',
  videoGroup: 'page_column_detail',
  // 询价页
  dealer: 'page_series_inquiry',
  // 进入参数配置页
  '/auto/params': 'page_series_configuration',
  // 车系对比页
  compare: 'page_series_compare_detail',
  // 进入搜索结果页
  '/search': 'page_search_result',
  '/video/groups': 'page_column_list',
  '/newcar': 'page_new_car_rank_list',
  '/sales': 'page_sales_rank_list',
  // 车友圈主页
  '/community': 'page_ugc_forum_home',
  // UGC文章详情页 - 图文/问答/小视频
  '/ugc/article': 'page_ugc_article_detail',
  // UGC文章详情页 - 口碑
  '/ugc/koubei': 'page_ugc_reputation_detail',
  // 车展专题
  '/activity/car_show': 'page_dcar_motor_show',
}
interface IStatData {
  page_id: string
  group_id?: string
  car_series_id?: string
  user_id?: string
  column_id?: string
  current_pic_rank?: string
}
type IGetStatData = (urlStr: string) => IStatData
const getStatData: IGetStatData = (urlStr: string) => {
  const url = urlStr.split('?').length > 0 ? urlStr.split('?')[0] : ''
  const regs: {
    [key: string]: RegExp
  } = {
    article: /article\/(\d+)$/,
    '/community': /community\/(\d+)$/,
    '/ugc/article': /ugc\/article\/(\d+)$/,
    '/ugc/koubei': /ugc\/koubei\/(\d+)$/,
    profile: /user\/profile\/(\d+)/,
    seriesImageskj: /auto\/series\/(\d+)\/images\/(?:\w+)/,
    seriesImages: /auto\/series\/(\d+)\/images$/,
    seriesVideo: /auto\/series\/(\d+)\/video$/,
    seriesVideoManual: /auto\/series\/(\d+)\/videoManual\/(\d+)$/,
    series: /auto\/series\/(\d+)$/,
    videoGroup: /video\/groups\/(\d+)/,
    dealer: /dealer\/(\d+)/,
    compare: /auto\/compare\/([\d,]+)/,
    '/auto': /auto\/library/,
  }
  let params: IStatData = {
    page_id: '',
  }
  if (pageIdMap[url]) {
    params = {
      page_id: pageIdMap[url],
    }
  } else {
    Object.keys(regs).forEach(reg => {
      const matches = url.match(regs[reg])
      if (matches) {
        params = {
          page_id: pageIdMap[reg],
          group_id: matches[1],
          car_series_id: matches[1],
          user_id: matches[1],
          column_id: matches[1],
        }
        if (matches?.length >= 2) {
          params.current_pic_rank =
            (reg === 'seriesImageskj' && matches[2]) || ''
        }
        return
      }
    })
  }

  return params
}
export default getStatData
