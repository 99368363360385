import 'intersection-observer'
import 'raf/polyfill'
// import 'core-js/features/array/from'
// import 'core-js'
// import 'core-js/features/set'
// import 'core-js/features/map'
import 'core-js/es/set'
import 'core-js/es/map'
import slardar from '@slardar/sdk'
import "child-replace-with-polyfill";
import getStatData from 'lib/page-enter'
// import 'core-js/features/promise'

if (process.env.NODE_ENV === 'production') {
  const params = getStatData(window.location.pathname) || {}
  slardar('config', {
    sampleRate: 1,
    bid: 'motor_pc',
    pid: params.page_id || 'home',
    ignoreAjax: ['mcs.snssdk.com'],
    hookFetch: true,
    ignoreStatic: [],
  })
  // 发送pv
  slardar('send', 'pageview')
}
